<template>
	<ZyroDrawer
		class="drawer"
		close-on-click-outside
		unset-element-on-open
	>
		<div class="style-list">
			<div class="drawer__title z-h5">
				<ZyroButton
					v-if="currentPageId !== $options.DEFAULT_PAGE_ID"
					icon="arrow-right"
					icon-dimensions="24px"
					class="style-list__back-button"
					@click="currentPageId = $options.DEFAULT_PAGE_ID"
				/>
				{{ currentPage.title }}
			</div>
			<Transition name="slide-left">
				<Component
					:is="currentPage.component"
					:element="currentPageId"
					@change-page="currentPageId = $event"
				/>
			</Transition>
		</div>
	</ZyroDrawer>
</template>

<script>
import {
	FETCH_GOOGLE_FONTS,
	mapActionsFonts,
} from '@/store/builder/fonts';

const PAGES = {
	'global-styles': {
		title: 'Styles',
		component: 'GlobalStyles',
	},
	'grid-button-primary': {
		title: 'Primary button',
		component: 'ButtonStyles',
	},
	'grid-button-secondary': {
		title: 'Secondary button',
		component: 'ButtonStyles',
	},
};
const DEFAULT_PAGE_ID = 'global-styles';

export default {
	PAGES,
	DEFAULT_PAGE_ID,
	components: {
		GlobalStyles: () => import('@/components/builder-drawers/drawers/partials/globalStylesDrawer/GlobalStyles.vue'),
		ButtonStyles: () => import('@/components/builder-drawers/drawers/partials/globalStylesDrawer/elements/type/ButtonStyles.vue'),
	},
	data() {
		return { currentPageId: DEFAULT_PAGE_ID };
	},
	computed: {
		currentPage() {
			return PAGES[this.currentPageId];
		},
	},
	async mounted() {
		await this.fetchGoogleFonts();
	},
	methods: { ...mapActionsFonts({ fetchGoogleFonts: FETCH_GOOGLE_FONTS }) },
};
</script>

<style lang="scss" scoped>
@import '@/components/builder-drawers/drawers/StylesDrawer.scss';
</style>
